<template>
    <v-layout class="d-flex" style="height: 100%">
        <div @click="naviModal = false" :class="[naviModal && 'active']" class="modal-sheet" />
        <v-hover @update:modelValue="updateHoveringNavigationTab">
            <template v-slot="{ isHovering, props }">
                <v-sheet
                    v-bind="props"
                    :class="[naviModal && 'active']"
                    class="svgPattern01 d-flex overflow-hidden flex-column analysis-navi-wrapper light-shadow py-5"
                    color="primary"
                    :style="navigationWidthVariable"
                >
                    <div>
                        <v-expand-transition>
                            <div v-if="isNavigationTabOpened || showText" class="d-flex flex-column align-center justify-center">
                                <img :height="`87px`" src="/images/IconWhite-RB.png" alt="Logo" />
                                <div class="mt-10" />
                            </div>
                        </v-expand-transition>

                        <div v-for="(link, i) in drawerLinks" :key="link.to" class="mb-2 a-n-container">
                            <div
                                v-if="!link.subLinks"
                                class="pl-6 pr-5 py-3 a-n-btn d-flex justify-space-between"
                                :class="[naviSelected(link.to) && 'highlight-btn lock-color']"
                                @click="navigateTo(link.to)"
                                :style="isNavigationTabOpened || showText ? 'max-height: 100px' : 'max-height: 45px'"
                            >
                                <div class="d-flex align-center a-n-title">
                                    <v-icon
                                        class="ml-1"
                                        size="22"
                                        :icon="link.icon"
                                        :class="[naviSelected(link.to) && 'text-highlightPrimary']"
                                    ></v-icon>

                                    <Transition name="fade" mode="in-out">
                                        <span
                                            v-if="isNavigationTabOpened || showText"
                                            class="ml-6 text-subtitle-2 font-weight-medium"
                                            :class="[naviSelected(link.to) && 'text-highlightPrimary']"
                                            style="min-width: 140px"
                                        >
                                            {{ link.text }}
                                        </span>
                                    </Transition>
                                </div>
                            </div>

                            <div v-else class="d-flex flex-column">
                                <div
                                    class="pl-6 pr-3 py-3 d-flex justify-space-between a-n-btn"
                                    :class="[subNaviSelected(link.to) && 'highlight-span']"
                                    @click="expandNavi(i)"
                                >
                                    <div class="d-flex align-center a-n-title">
                                        <v-icon :icon="link.icon"></v-icon>
                                        <span
                                            class="ml-6 text-subtitle-2 font-weight-medium"
                                            :class="[naviSelected(link.to) && 'text-highlightPrimary']"
                                        >
                                            {{ link.text }}
                                        </span>
                                    </div>
                                    <span class="a-n-sub-icon" :class="[link.subOpen && 'subcontent-open']">
                                        <v-icon size="20" icon="i-mdi:chevron-right"></v-icon>
                                    </span>
                                </div>

                                <div class="a-n-subcontent" :class="[link.subOpen && 'subcontent-open']">
                                    <div
                                        v-for="sublink in link.subLinks"
                                        :key="sublink.to"
                                        class="a-n-btn"
                                        :class="[naviSelected(sublink.to) && 'highlight-btn']"
                                    >
                                        <div class="d-flex align-center px-5 py-3 ml-5 my-2" @click="navigateTo(sublink.to)">
                                            <v-icon size="25" icon="i-mdi:circle-small"></v-icon>
                                            <span
                                                class="ml-6 text-subtitle-2 font-weight-medium"
                                                :class="[naviSelected(sublink.to) && 'text-highlightPrimary']"
                                            >
                                                {{ sublink.text }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <v-spacer />

                    <div class="mb-2 a-n-container">
                        <div
                            @click="signOutDialog = true"
                            class="pl-6 pr-5 py-3 a-n-btn d-flex justify-space-between"
                            :style="isNavigationTabOpened || showText ? 'max-height: 100px' : 'max-height: 45px'"
                        >
                            <div class="d-flex align-center a-n-title">
                                <v-icon class="ml-1" size="22" icon="i-mdi:logout"></v-icon>

                                <Transition name="fade" mode="in-out">
                                    <span
                                        v-if="isNavigationTabOpened || showText"
                                        class="ml-7 text-subtitle-2 font-weight-medium"
                                        style="min-width: 140px"
                                    >
                                        Sign Out
                                    </span>
                                </Transition>
                            </div>
                        </div>
                    </div>
                </v-sheet>
            </template>
        </v-hover>

        <v-btn color="cardPrimary" class="navi-modal-control-btn" @click="naviModal = !naviModal">
            <v-icon icon="i-mdi:menu" class="ml-n1" size="22"></v-icon>
        </v-btn>

        <div class="analysis-content" :style="analysisContentWidth">
            <div class="svgPattern01 light-shadow mx-n1" style="height: 80px"></div>
            <div class="py-5" :class="[contentPadding]">
                <slot />
            </div>
        </div>

        <!-- Dialog to sign out -->
        <v-dialog v-model="signOutDialog" max-width="400" persistent>
            <v-card color="background" class="pa-3">
                <v-card-title class="text-h6 font-weight-bold">Sign Out</v-card-title>
                <p class="text-subtitle-2 font-weight-medium px-5 py-3">Do you want to proceed with signing out?</p>
                <v-card-actions :class="xs ? 'flex-column w-100' : 'flex-row'">
                    <v-spacer />
                    <v-btn :block="xs" color="primary" variant="text" @click="signOutDialog = false">Cancel</v-btn>
                    <v-btn :block="xs" color="primary" variant="flat" @click.stop="signOut()" width="110">Sign Out</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script setup lang="ts">
// -------------------- Signout --------------------
const { logoutUser } = useUserDetails();

const signOutDialog = ref(false);

const signOut = async () => {
    signOutDialog.value = false;
    logoutUser();
    // For some reason, when screen is md or smaller, the navigation tab is causing navigation block?
    router.push({ path: "/" });
};

// -------------------- Breakpoint --------------------
const { xs, mdAndDown, smAndDown } = useDisplay();

const contentPadding = computed(() => {
    if (xs.value) {
        return "";
    }

    if (smAndDown.value) {
        return "px-3";
    }
    return "px-7";
});

import { useWindowSize } from "@vueuse/core";

const isNavigationTabOpened = ref(false);
const updateHoveringNavigationTab = (isHovering: boolean) => {
    if (naviModal.value) return;

    if (!isHovering) {
        isNavigationTabOpened.value = false;
    } else {
        isNavigationTabOpened.value = true;
    }
};

const { width: currentWindowWidth } = useWindowSize();

const analysisContentWidth = computed(() => {
    if (currentWindowWidth.value > 959 && currentWindowWidth.value < 1536) {
        if (isNavigationTabOpened.value) {
            return `min-width: calc(100%); opacity: 0.5;`;
        } else {
            return `min-width: calc(100% - 75px);`;
        }
    } else {
        return `min-width: calc(100% - 250px)`;
    }
});

const navigationWidthVariable = computed(() => {
    if (currentWindowWidth.value > 959 && currentWindowWidth.value < 1536) {
        return {
            "--navigation-width": isNavigationTabOpened.value ? 250 + "px" : 75 + "px",
        };
    }

    return {
        "--navigation-width": 250 + "px",
    };
});

const showText = computed(() => {
    if (currentWindowWidth.value > 959 && currentWindowWidth.value < 1536) {
        return false;
    } else {
        return true;
    }
});

// -------------------- Navigation --------------------
interface AnalysisNavItem {
    to: string;
    icon: string;
    text: string;
    subOpen?: boolean;
    subLinks?: {
        text: string;
        to: string;
    }[];
}

const naviModal = ref(false);
const drawerLinks = ref<AnalysisNavItem[]>([
    {
        to: "/cms",
        icon: "i-mdi:monitor",
        text: "Case Files",
    },
    {
        to: "/cms/newsArticles",
        icon: "i-mdi:wrench-outline",
        text: "News Articles",
    },
    {
        to: "/cms/contactForms",
        icon: "i-mdi:solar-power-variant-outline",
        text: "Contact Forms",
    },
]);

const router = useRouter();

const naviSelected = (_currentPath: string) => {
    return router.currentRoute.value.path === _currentPath;
};

const subNaviSelected = (_currentPath: string) => {
    return router.currentRoute.value.path.includes(_currentPath);
};

const expandNavi = (index: number) => {
    drawerLinks.value[index].subOpen = !drawerLinks.value[index].subOpen;
};

const navigateTo = async (path: string) => {
    if (isNavigationTabOpened.value || naviModal.value || currentWindowWidth.value > 1536) {
        naviModal.value = false;
        isNavigationTabOpened.value = false;
        router.push({ path });
    } else {
        isNavigationTabOpened.value = true;
    }
};
</script>

<style lang="scss" scoped>
.modal-sheet {
    display: none;
    z-index: 5;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(var(--v-theme-onPrimary), 0.75);
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}

.navi-modal-control-btn {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 0 5px 20px 0px;
    width: 60px;
    height: 40px;
}

.analysis-content {
    transition: all 0.3s ease-in-out;
}

.analysis-navi-wrapper {
    position: relative;
    border-radius: 0 5px 5px 0;
    max-width: var(--navigation-width);
    min-width: var(--navigation-width);
    transition: all 0.3s ease-in-out;
}

.a-n-container {
    width: 93%;
}

.a-n-btn {
    cursor: pointer;
    opacity: 0.9;
    border-radius: 0 10px 10px 0;
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: rgba(var(--v-theme-onPrimary), 0.1);
    }
}

.highlight-btn {
    opacity: 1 !important;
    background-color: rgb(var(--v-theme-onPrimary));
    border-radius: 0 10px 10px 0;

    &.lock-color {
        background-color: rgb(var(--v-theme-onPrimary)) !important;
    }
}

.highlight-span {
    opacity: 1;
}

.a-n-subcontent {
    opacity: 0;
    max-height: 0;
    transition: opacity 0.3s ease-in-out, max-height 0.5s ease-in-out;
    overflow: hidden;

    &.subcontent-open {
        opacity: 1;
        max-height: 500px;
    }
}

.a-n-sub-icon {
    transition: transform 0.25s ease-in-out;
    transform: rotate(0deg);

    &.subcontent-open {
        transform: rotate(90deg);
    }
}

// If screen is smaller lg
@media (max-width: 959px) {
    .modal-sheet {
        opacity: 0;
        display: block;
        pointer-events: none;

        &.active {
            opacity: 1;
            pointer-events: all;
        }
    }

    .navi-modal-control-btn {
        display: block;
    }

    .analysis-navi-wrapper {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 10;
        left: -30%;
        opacity: 0;
        pointer-events: none;

        &.active {
            pointer-events: all;
            opacity: 1;
            left: 0%;
        }
    }

    .analysis-content {
        width: 100%;
    }
}
</style>
